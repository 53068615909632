var site = site || {};

(function($) {

var loadingSets = [];

site.loading = { 
  show: function (args) {
    var attrs = {
          "class": "loading-status",
          "css": {}
        },
        $target = $(args.target),
        $loadingImg = $("<img/>", { "src": "/media/export/cms/479-01 Clinique_LoadingGif_R22.gif", "css": {"height": "100%"} }),
        $loading;
    if (args.matchTarget && args.matchTarget.length > 0) {
      for (var i = 0; i < args.matchTarget.length; i += 1) {
        attrs.css[args.matchTarget[i]] = $target.css(args.matchTarget[i]);
      }
    }
    if (args.css) {
      jQuery.extend(attrs.css, args.css);
    }

    $loading = $("<div/>", attrs).append($loadingImg);
    $target.hide().before($loading);
    loadingSets.push({"target": $target, "loading": $loading});
    return loadingSets.length - 1;
  },
  hide: function (id) {
    if (id == null) { // if the id is null or undefined, i.e., not 0 or any non-zero index
      id = loadingSets.length - 1;
    }
    if(loadingSets[id] !== undefined) {
      loadingSets[id].loading.remove();
      loadingSets[id].target.show();
      delete loadingSets[id];
    }
  }
};

})(jQuery);
